export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "ArchiveIcon",
  },
  {
    title: "Reports",
    icon: "TrelloIcon",
    children: [
      {
        title: "Course completation",
        route: "courseCompletion",
      },
    ],
  },
  {
    title: "Frontend CMS",
    icon: "CodepenIcon",
    children: [
      {
        title: "Home",
        route: "home",
      },
      {
        title: "About",
        route: "about",
      },
      {
        title: "Certification",
        route: "certification",
      },
      {
        title: "FAQ",
        route: "faq",
      },
      {
        title: "Student Feedback",
        route: "studentFeedback",
      },
      {
        title: "Contact US",
        route: "contactUs",
      },
      {
        title: "Privacy Policy",
        route: "privacyPolicy",
      },
      {
        title: "Terms Of Use",
        route: "terms",
      },
      {
        title: "Footer",
        route: "footer",
      },
      {
        title: "Option",
        route: "option",
      },
      {
        title: "Partner",
        route: "partner",
      },
    ],
  },
  {
    header: "Course",
    icon: "CodepenIcon",
    children: [
      {
        title: "Category",
        route: "category",
      },
      {
        title: "Course",
        route: "course",
      },
    ],
  },

  {
    header: "Setting",
    icon: "SettingsIcon",
    children: [
      {
        title: "Teacher",
        route: "teacher",
      },
      {
        title: "Users",
        route: "users",
      },
      {
        title: "Role",
        route: "roles",
      },
    ],
  },
];
